var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard-admin"},[_c('aside',{staticClass:"sidebar",attrs:{"id":"leftsidebar"}},[_c('div',{staticClass:"menu"},[_c('ul',{staticClass:"list"},_vm._l((_vm.listMenu),function(value,index){return _c('li',{key:index},[_c('div',{class:{ active: _vm.returnActive(index) }},[_c('p',{staticClass:"mb-0",on:{"click":function($event){return _vm.openMenu(index)}}},[_c('router-link',{staticClass:"menu-toggle menu1 position-relative",attrs:{"to":{ name: value.routeName }}},[(value.icon)?_c('b-icon',{staticClass:"my-auto",attrs:{"icon":value.icon,"font-scale":"1.5"}}):_c('img',{staticClass:"my-auto",attrs:{"src":value.img,"alt":""}}),_c('span',[_vm._v(_vm._s(value.textParent))]),(value.child && value.child.length > 0)?_c('span',{staticClass:"position-absolute",staticStyle:{"right":"14px"}},[(_vm.returnDis(index) == 'unset')?_c('b-icon',{attrs:{"icon":"dash"}}):_c('b-icon',{attrs:{"icon":"plus"}})],1):_vm._e()],1)],1),(value.child && value.child.length > 0)?_c('ul',{staticClass:"ml-menu ml-menu-1",style:({ display: _vm.returnDis(index) })},_vm._l((value.child),function(item,ind){return _c('li',{key:ind},[(item.parts && item.parts.length > 0)?_c('div',{staticClass:"menu-part",class:{
                    activeItem: _vm.activeIndexMenu == ind && _vm.returnActive(index),
                  },on:{"click":function($event){return _vm.setItemMenuParent(ind)}}},[_c('div',{staticClass:"mb-0"},[(item.parts.length > 0)?_c('img',{staticStyle:{"width":"20px","height":"16px"},attrs:{"src":_vm.returnDisPart(ind) ? _vm.folder_open : _vm.folder_lock}}):_vm._e(),_c('span',[_vm._v(_vm._s(item.text))]),(item.parts.length > 0)?_c('img',{staticStyle:{"position":"absolute","right":"15px","color":"#555","width":"16px","height":"13px"},attrs:{"src":_vm.returnDisPart(ind) ? _vm.dir_open : _vm.dir_close}}):_vm._e()]),_c('ul',{staticClass:"pl-3",style:({
                      display: _vm.returnDisPart(ind) ? '' : 'none',
                    })},_vm._l((item.parts),function(part,idPart){return _c('li',{key:idPart,staticClass:"mt-3",on:{"click":function($event){return _vm.setItemMenuPart(ind, idPart)}}},[_c('router-link',{staticClass:"py-0",staticStyle:{"padding-left":"20px"},attrs:{"to":{ name: part.route }}},[_c('img',{staticClass:"my-auto",staticStyle:{"width":"20px","height":"20px"},attrs:{"src":_vm.cicrle_chevron}}),(_vm.activeIndexMenuPart != idPart)?_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v(_vm._s(part.text))]):_c('span',{class:{
                            activePart:
                              _vm.activeIndexMenuPart == idPart &&
                              _vm.activeIndexMenu == ind,
                          }},[_vm._v(_vm._s(part.text))])])],1)}),0)]):_c('div',{staticClass:"menu-none-part",class:{
                    activeItem: _vm.activeIndexMenu == ind && _vm.returnActive(index),
                  },on:{"click":function($event){return _vm.setItemMenu(ind)}}},[(item.roleOwner == true)?_c('div',[(_vm.user_type == _vm.typeOwner || _vm.user_type == _vm.typeCDEA)?_c('div',[(item.newTab == true)?_c('a',{attrs:{"href":item.route != null ? item.route : '/',"target":"_blank"}},[(_vm.activeIndexMenu == ind && _vm.returnActive(index))?_c('b-icon',{staticClass:"my-auto",attrs:{"icon":"caret-right-fill"}}):_vm._e(),_c('span',{style:(_vm.activeIndexMenu == ind && _vm.returnActive(index)
                              ? 'margin-left: 15px'
                              : 'margin-left: 26px')},[_vm._v(_vm._s(item.text))])],1):_c('router-link',{attrs:{"to":{ name: item.route }}},[(_vm.activeIndexMenu == ind && _vm.returnActive(index))?_c('b-icon',{staticClass:"my-auto",attrs:{"icon":"caret-right-fill"}}):_vm._e(),_c('span',{style:(_vm.activeIndexMenu == ind && _vm.returnActive(index)
                              ? 'margin-left: 15px'
                              : 'margin-left: 26px')},[_vm._v(_vm._s(item.text))])],1)],1):_vm._e()]):_c('div',[_c('router-link',{attrs:{"to":{ name: item.route }}},[(_vm.activeIndexMenu == ind && _vm.returnActive(index))?_c('b-icon',{staticClass:"my-auto",attrs:{"icon":"caret-right-fill"}}):_vm._e(),_c('span',{style:(_vm.activeIndexMenu == ind && _vm.returnActive(index)
                            ? 'margin-left: 15px'
                            : 'margin-left: 26px')},[_vm._v(_vm._s(item.text))])],1)],1)])])}),0):_vm._e()])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }