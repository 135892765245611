<template>
  <div class="admin-layout d-flex flex-column h-100 custome-sp">
    <!-- <div
      v-bind:class="{ showPreview: !isShow }"
      style="min-height: 100vh; position: relative"
    >
      <PreviewColumn />
    </div> -->
    <div v-bind:class="{ checkPreview: isShow }">
      <Header />
      <MenuSP />
      <div v-bind:class="{ overlay: !isActive }">
        <div class="layout-admin-content">
          <b-container fluid>
            <b-row class="g-0">
              <b-col cols="12" class="d-flex">
                <div class="desktop-admin">
                  <Leftbar />
                </div>
                <div class="layout-admin-content-main w-70">
                  <router-view :key="$route.fullPath"></router-view>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <Footer :isActive="isActive" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Footer from "./Footer";
import Leftbar from "./Leftbar";
import MenuSP from "./MenuSP";
// import PreviewColumn from "../../views/admin/PreviewColumn";
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";

export default {
  name: "Layout",
  components: {
    Header,
    Footer,
    Leftbar,
    MenuSP,
    // PreviewColumn,
  },
  data() {
    return {
      isActive: false,
      checkShow: false,
      isShow: false,
      shopId: this.$route.params.shopId || null,
    };
  },
  created() {
    if (this.$route.fullPath.includes("fixed-page/pre_column_page")) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
    if (
      !this.$route.fullPath.includes("admin/page/") &&
      !this.$route.fullPath.includes("preview/page")
    ) {
      localStorage.removeItem(Constants.REVIEW_PAGE);
    }
    let request = {
      domain: Constants.DOMAIN,
      shop_id: this.shopId,
    };
    this.getShopById(request);
  },
  watch: {
    $route() {
      if (this.$route.fullPath.includes("fixed-page/pre_column_page")) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      if (
        !this.$route.fullPath.includes("admin/page/") &&
        !this.$route.fullPath.includes("preview/page")
      ) {
        localStorage.removeItem(Constants.REVIEW_PAGE);
      }
      if (
        !this.$route.fullPath.includes("fixed-page/create") &&
        !this.$route.fullPath.includes("fixed-page/edit/") &&
        !this.$route.fullPath.includes("fixed-page/pre_column_page") &&
        !this.$route.fullPath.includes("fixed-page/preview")
      ) {
        localStorage.removeItem(Constants.PAGE_PREVIEW);
        localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
        this.$store.commit("set", ["checkHeaderImg", ""]);
        this.$store.commit("set", ["checkBackgroundImg", ""]);
        this.$store.commit("set", ["checkArticleImg", ""]);
      }
    },
    detailShop() {
      const extConstant = this.$route.params.shopId
        ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
        : "_" + Constants.DOMAIN;
      localStorage.setItem(
        Constants.BI_SHOP + extConstant,
        this.detailShop.url_bi
      );
    },
  },
  computed: {
    ...mapGetters(["detailShop"]),
  },
  methods: {
    ...mapActions({ getShopById: "getShopById" }),
    toggleSidebar(data) {
      this.isActive = data;
    },
  },
};
</script>

<style lang="scss">
// Import css slag
@import "../../views/admin/admin.scss";
@import "../../views/admin/Responsive.scss";
</style>

<style lang="scss" scoped>
.admin-layout {
  background-color: #f4f3ef;
  .showPreview {
    display: none;
  }
  .checkPreview {
    display: none;
  }
  .menu-sidebar.active {
    padding: 10px;
  }
  .layout-admin-content {
    .col-12 {
      padding: unset !important;
    }
    &-main {
      width: 100%;
      min-height: calc(100vh - 103px);
      .card-content {
        .stripe-input {
          width: calc(100% - 350px);
          height: 40px;
        }
        .stripe-btn {
          width: 100px;
        }
      }
    }
    .main.active {
      margin: 60px 0 50px 70px;
    }
  }
}
</style>
