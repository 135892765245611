<template>
  <div class="dashboard-admin mobile-admin">
    <aside
      id="leftsidebar"
      class="sidebar menusp"
      v-bind:class="{ showmenu: showHambuger }"
    >
      <!-- Menu -->
      <div class="menu" v-click-outside="closeMenu">
        <ul class="list">
          <li v-for="(value, index) in listMenu" :key="index">
            <div :class="{ active: returnActive(index) }">
              <p @click="openMenu(index)" class="mb-0">
                <router-link
                  :to="{ name: value.routeName }"
                  class="menu-toggle menu1 position-relative"
                >
                  <b-icon
                    v-if="value.icon"
                    :icon="value.icon"
                    class="my-auto"
                    font-scale="1.5"
                  ></b-icon>
                  <img v-else :src="value.img" alt="" class="my-auto" />
                  <span>{{ value.textParent }}</span>
                  <span
                    class="position-absolute"
                    style="right: 14px"
                    v-if="value.child && value.child.length > 0"
                  >
                    <b-icon
                      icon="dash"
                      v-if="returnDis(index) == 'unset'"
                    ></b-icon>
                    <b-icon icon="plus" v-else></b-icon>
                  </span>
                </router-link>
              </p>
              <ul
                v-if="value.child && value.child.length > 0"
                class="ml-menu ml-menu-1"
                :style="{ display: returnDis(index) }"
              >
                <li v-for="(item, ind) in value.child" :key="ind">
                  <div
                    v-if="item.parts && item.parts.length > 0"
                    class="menu-part"
                    @click="setItemMenuParent(ind)"
                    :class="{
                      activeItem: activeIndexMenu == ind && returnActive(index),
                    }"
                  >
                    <p class="mb-0">{{ item.text }}</p>
                    <ul class="pl-3" :style="{ display: returnDisPart(ind) }">
                      <li
                        v-for="(part, idPart) in item.parts"
                        :key="idPart"
                        @click="setItemMenuPart(ind, idPart)"
                      >
                        <router-link :to="{ name: part.route }" class="pl-0">
                          <b-icon
                            v-if="
                              activeIndexMenuPart == idPart &&
                              activeIndexMenu == ind
                            "
                            icon="caret-right-fill"
                            class="my-auto"
                          ></b-icon>
                          <span
                            v-if="activeIndexMenuPart != idPart"
                            style="font-weight: normal"
                            >{{ part.text }}</span
                          >
                          <span v-else>{{ part.text }}</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div
                    :class="{
                      activeItem: activeIndexMenu == ind && returnActive(index),
                    }"
                    v-else
                    @click="setItemMenu(ind)"
                  >
                    <div v-if="item.roleOwner == true">
                      <div
                        v-if="user_type == typeOwner || user_type == typeCDEA"
                      >
                        <a
                          v-if="item.newTab == true"
                          :href="item.route != null ? item.route : '/'"
                          target="_blank"
                        >
                          <b-icon
                            v-if="activeIndexMenu == ind && returnActive(index)"
                            icon="caret-right-fill"
                            class="my-auto"
                          ></b-icon>
                          <span>{{ item.text }}</span>
                        </a>
                        <router-link v-else :to="{ name: item.route }">
                          <b-icon
                            v-if="activeIndexMenu == ind && returnActive(index)"
                            icon="caret-right-fill"
                            class="my-auto"
                          ></b-icon>
                          <span>{{ item.text }}</span>
                        </router-link>
                      </div>
                    </div>
                    <div v-else>
                      <router-link :to="{ name: item.route }">
                        <b-icon
                          v-if="activeIndexMenu == ind && returnActive(index)"
                          icon="caret-right-fill"
                          class="my-auto"
                        ></b-icon>
                        <span>{{ item.text }}</span>
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <!-- #Menu -->
    </aside>
  </div>
</template>
<script>
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants";
import { mapGetters } from "vuex";
import moment from "moment";
import vClickOutside from "v-click-outside";
import dashboardCreate from "@/assets/img/dashboard-create.png";
import dashboardEdit from "@/assets/img/dashboard-edit.png";
import dashboardBox from "@/assets/img/dashboard-box.png";
import dashboardSetting from "@/assets/img/dashboard-setting.png";

export default {
  name: "Leftbar",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      isLoading: false,
      detailNew: null,
      indexSelect: null,
      page: 1,
      direct: null,
      callFirstTime: false,
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeOwner: Constants.USER_TYPE_DEFAULT["owner"],
      typeCDEA: Constants.USER_TYPE_DEFAULT["cdea"],
      listMenu: [
        {
          textParent: "ホーム",
          routeName: "Dashboard Admin",
          icon: "house-fill",
          child: [],
        },
        {
          textParent: "作成メニュー",
          routeName: "DashboardAdminCreate",
          img: dashboardCreate,
          child: [
            { text: "コンテンツ作成", route: "create page" },
            { text: "コンテンツ公開設定", route: "Content Change" },
            { text: "単発ページ", route: "createFixedPage" },
            {
              text: "オファー作成",
              route: "Content Sale Create",
            },
          ],
        },
        {
          textParent: "編集メニュー",
          routeName: "DashboardAdminEdit",
          img: dashboardEdit,
          child: [
            { text: "コンテンツ一覧", route: "list content" },
            { text: "コンテンツ公開一覧", route: "list page" },
            { text: "カテゴリ管理", route: "List Category" },
            { text: "オファー管理", route: "list sale" },
          ],
        },
        {
          textParent: "ツールBOX",
          routeName: "DashboardAdminBox",
          img: dashboardBox,
          child: [
            {
              text: "売上管理",
              route: "",
              parts: [
                { text: "注文管理", route: "ListOrder" },
                { text: "売上管理", route: "Menu SaleManagerment" },
              ],
            },
            {
              text: "URL",
              route: "",
              parts: [
                { text: "URL作成", route: "CreateURL" },
                { text: "URL管理", route: "URLManager" },
              ],
            },
            {
              text: "ユーザー管理",
              route: "",
              parts: [
                { text: "ユーザー管理", route: "list user" },
                { text: "問合せ管理", route: "list inquiry" },
                { text: "ノート管理", route: "List Note" },
                { text: "質問管理", route: "Chat Admin" },
                { text: "カート利用中のユーザー一覧", route: "List Cart" },
              ],
            },
            { text: "登録メルマガ管理", route: "List Mail Mega" },
            {
              text: "ニュース",
              route: "",
              parts: [
                { text: "ニュース管理", route: "list new admin" },
                { text: "ニュースカテゴリ管理", route: "list category new" },
              ],
            },
            { text: "スラグ管理", route: "list slag" },
            {
              text: "データ確認",
              route:
                localStorage.getItem(
                  Constants.BI_SHOP + this.$route.params.shopId
                    ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                    : "_" + Constants.DOMAIN
                ) &&
                localStorage.getItem(
                  Constants.BI_SHOP + this.$route.params.shopId
                    ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                    : "_" + Constants.DOMAIN
                ) != "null"
                  ? localStorage.getItem(
                      Constants.BI_SHOP + this.$route.params.shopId
                        ? "_" +
                            Constants.DOMAIN +
                            "_" +
                            this.$route.params.shopId
                        : "_" + Constants.DOMAIN
                    )
                  : "",
              roleOwner: true,
              newTab: true,
            },
            { text: "著者管理", route: "ListAuthor" },
            { text: "メニュー設定", route: "menuManager" },
            // { text: "ポイント設定", route: "list point" },
          ],
        },
        {
          textParent: "全体設定",
          routeName: "DashboardAdminSetting",
          img: dashboardSetting,
          child: [
            { text: "一般設定", route: "General Setting", roleOwner: true },
            { text: "SNS設定", route: "SNS Setting", roleOwner: true },
            { text: "決済情報登録", route: "paymentSetting", roleOwner: true },
            { text: "販売情報設定", route: "Sales Setting", roleOwner: true },
            { text: "ストア設定", route: "store setting", roleOwner: true },
            { text: "メール設定", route: "email setting" },
          ],
        },
        // {
        //   textParent: "他のメニュー",
        //   routeName: "DashboardAdminOther",
        //   img: iconDefault,
        //   child: [
        //     { text: "お問い合わせ一覧", route: "list inquiry" },
        //     { text: "ページ管理", route: "list page" },
        //     { text: "トリガー管理", route: "List Trigger" },
        //     { text: "ニュースカテゴリ管理", route: "list category new" },
        //     { text: "アンケート管理", route: "list survey" },
        //   ],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "listContent",
      "message",
      "success",
      "error",
      "isActive0",
      "isActive1",
      "isActive2",
      "isActive3",
      "isActive4",
      "isActive5",
      "dis1",
      "dis2",
      "dis3",
      "dis4",
      "dis5",
      "showHambuger",
      "activeIndexMenu",
      "activeIndexMenuPart",
      "getShopById",
    ]),
  },
  async created() {
    // let request = {
    //   domain: Constants.DOMAIN,
    //   shop_id: this.$route.params.shopId || null,
    // };
    // await this.$store.dispatch("getShopById", request);
    // const formData = {
    //   page: this.page,
    //   data: {
    //     shop_id: response.data.id,
    //     limit: 5,
    //   },
    // };
    // if (this.user_type == this.typeOwner || this.user_type == this.typeCDEA) {
    //   this.getListNewAdminDashboard(formData);
    // }
    if (this.$route.fullPath.includes("dashboard/create")) {
      this.$store.commit("set", ["isActive1", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/edit")) {
      this.$store.commit("set", ["isActive2", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/box")) {
      this.$store.commit("set", ["isActive3", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/setting")) {
      this.$store.commit("set", ["isActive4", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard/other")) {
      this.$store.commit("set", ["isActive5", true]);
      this.$store.commit("set", ["isActive0", false]);
    } else if (this.$route.fullPath.includes("dashboard")) {
      this.$store.commit("set", ["isActive0", true]);
      this.$store.commit("set", ["isActive1", false]);
      this.$store.commit("set", ["isActive2", false]);
      this.$store.commit("set", ["isActive3", false]);
      this.$store.commit("set", ["isActive4", false]);
    }
  },
  watch: {
    $route() {
      if (this.$route.fullPath.includes("dashboard/create")) {
        this.$store.commit("set", ["isActive1", true]);
      } else if (this.$route.fullPath.includes("dashboard/edit")) {
        this.$store.commit("set", ["isActive2", true]);
      } else if (this.$route.fullPath.includes("dashboard/box")) {
        this.$store.commit("set", ["isActive3", true]);
      } else if (this.$route.fullPath.includes("dashboard/setting")) {
        this.$store.commit("set", ["isActive4", true]);
      } else if (this.$route.fullPath.includes("dashboard/other")) {
        this.$store.commit("set", ["isActive5", true]);
        this.$store.commit("set", ["isActive0", false]);
      } else if (this.$route.fullPath.includes("dashboard")) {
        this.$store.commit("set", ["isActive0", true]);
      }
    },
    page() {
      this.getListNews(this.page);
    },
    listNewAdminDashboard() {
      if (this.listNewAdminDashboard.data.length && this.callFirstTime) {
        this.indexSelect =
          this.direct === "next"
            ? 0
            : this.listNewAdminDashboard.data.length - 1;
        this.detailNew = this.listNewAdminDashboard.data.filter(
          (item, index) => index === this.indexSelect
        )[0];
      } else {
        this.callFirstTime = true;
      }
    },
  },
  methods: {
    moment,
    logoutBtn() {
      this.isLoading = true;
      Api.adminRequestServer
        .post(`/${Urls.LOGOUT}`)
        .then((response) => {
          const { data } = response;
          const shopId = this.$route.params.shopId;
          this.isLoading = false;
          if (data.success) {
            this.$toasted.success(data.message);
            this.$store.commit("set", ["success", false]);
            this.$store.commit("set", ["message", ""]);
            localStorage.removeItem(
              Constants.USER_TYPE_ADMIN + this.$route.params.shopId
                ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                : "_" + Constants.DOMAIN
            );
            localStorage.removeItem(
              Constants.TOKEN_ADMIN + this.$route.params.shopId
                ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                : "_" + Constants.DOMAIN
            );
            localStorage.removeItem(
              Constants.ADMIN_USER_INFO + this.$route.params.shopId
                ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
                : "_" + Constants.DOMAIN
            );
            if (shopId) {
              this.$router.push({
                name: "login admin",
                params: { shopId: shopId },
              });
            } else {
              this.$router.push({
                name: "login admin domain",
              });
            }
          } else {
            this.$toasted.error(data.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toasted.error(error.message);
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
        });
    },
    loginUser() {
      this.isLoading = true;
      const self = this;
      if (localStorage.getItem(Constants.TOKEN_USER)) {
        Api.userRequestServer
          .post(Urls.LOGOUT)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              this.$store.commit("set", ["success", false]);
              this.$store.commit("set", ["message", ""]);
              localStorage.removeItem(Constants.USER_TYPE_USER);
              localStorage.removeItem(Constants.TOKEN_USER);
              localStorage.removeItem(Constants.NORMAL_USER_INFO);
              localStorage.removeItem(Constants.LIST_SLAG);
            } else {
              this.$toasted.error(data.message);
              this.$store.commit("set", ["message", ""]);
              this.$store.commit("set", ["error", false]);
            }
          })
          .catch((error) => {
            this.$toasted.error(error.message);
            this.$store.commit("set", ["message", ""]);
            this.$store.commit("set", ["error", false]);
          });
      }
      setTimeout(function () {
        const shopId = this.$route.params.shopId;
        if (shopId) {
          self.$router.push({
            name: "library",
            params: { shopId: shopId },
          });
        } else {
          self.$router.push({
            name: "library domain",
            params: { shopId: shopId },
          });
        }
      }, 2000);
    },
    showDetail(id, index) {
      this.indexSelect = index;
      this.detailNew = this.listNewAdminDashboard.data.filter(
        (item) => item.id === id
      )[0];
      this.$bvModal.show("detail-new");
    },
    openMenu(index) {
      for (var j = 0; j < Constants.COUNT_DASHBOARD; j++) {
        var ind = "isActive" + j;
        var dis = "dis" + j;
        if (j != index) {
          this.$store.commit("set", [dis, "none"]);
          this.$store.commit("set", [ind, false]);
        } else {
          if (this[dis] == "unset") {
            this.$store.commit("set", [dis, "none"]);
          } else {
            this.$store.commit("set", [dis, "unset"]);
            this.$store.commit("set", [ind, true]);
          }
        }
      }
      if (index == 0) this.$store.commit("set", ["showHambuger", false]);
      this.$store.commit("set", ["activeIndexMenu", -1]);
      this.$store.commit("set", ["activeIndexMenuPart", -1]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    closeMenu() {
      this.$store.commit("set", ["showHambuger", false]);
    },
    setItemMenu(index) {
      this.$store.commit("set", ["activeIndexMenu", index]);
      this.$store.commit("set", ["activeIndexMenuPart", -1]);
      this.$store.commit("set", ["showHambuger", false]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    returnActive(index) {
      return this["isActive" + index];
    },
    returnDis(index) {
      return this["dis" + index];
    },
    setItemMenuPart(ind, idPart) {
      this.$store.commit("set", ["activeIndexMenu", ind]);
      this.$store.commit("set", ["activeIndexMenuPart", idPart]);
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    setItemMenuParent(index) {
      var check = this.activeIndexMenu;
      this.$store.commit("set", ["activeIndexMenu", index]);
      if (check != index) {
        this.$store.commit("set", ["activeIndexMenuPart", -1]);
      }
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
    },
    returnDisPart(index) {
      return this.activeIndexMenu == index ? "" : "none";
    },
  },
};
</script>
